<template>
<v-container fluid class="pa-0">
  <v-card
    :height="$vuetify.breakpoint.smAndDown ? '':'350px'"
    elevation="0"
    class="formatCards"
    color="#F2F4F6"
  >
    <v-card-text :class="$vuetify.breakpoint.smAndDown ? 'pa-0' : 'pa-0 pt-6'">
      <v-row>
        <v-col
          lg="10"
          md="10"
          sm="12"
          cols="12"
          :class="$vuetify.breakpoint.smAndDown ? 'my-0 pt-0 pb-0' : 'my-0 pb-0 pt-1'"
        >
          <v-img
            :height="$vuetify.breakpoint.smAndDown ? '196px' : '155px'"
            :aspect-ratio="13/5"
            :src="img"
            lazy-src="@/assets/img/Loading.gif"
          >
        </v-img>
        </v-col>
        <v-col
          v-if="!$vuetify.breakpoint.smAndDown"
          cols="12"
          class="subTituloSimple"
          @click="editar('home.wewanttobelist', true, numElement, 'titulo')"
        >
          {{titulo}}
        </v-col>
        <v-col
          v-if="!$vuetify.breakpoint.smAndDown"
          lg="10"
          md="10"
          sm="11"
          cols="11"
          class="textoSimple"
          @click="editar('home.wewanttobelist', true, numElement, 'detalle')"
        >
          {{detalle}}
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions v-if="!$vuetify.breakpoint.smAndDown"  style="position: absolute; bottom: 0; right: 0;">
        <v-row width="100%" class="justify-content: flex-end;">
          <v-col cols="12">
            <v-btn @click="redirect(id)" text color="red">{{textButton}}</v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
  </v-card>
  <v-row v-if="$vuetify.breakpoint.smAndDown" justify="center" style="margin-top: 20px;">
    <v-col
          cols="12"
          class="subTituloSimple text-center mb-6"
          @click="editar('home.wewanttobelist', true, numElement, 'titulo')"
        >
          {{titulo}}
        </v-col>
        <v-col
          lg="10"
          md="10"
          sm="11"
          cols="11"
          class="textoSimple mb-5"
          @click="editar('home.wewanttobelist', true, numElement, 'detalle')"
        >
          {{detalle}}
        </v-col>
  </v-row>
  </v-container>
</template>
<script>
export default {
  props: {
    titulo: {
      type: String,
      required: true,
    },
    img: {
      type: String,
      required: true,
    },
    detalle: {
      type: String,
      required: true,
    },
    numElement: {
      type: Number,
      required: true,
    },
    textButton: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      default: 'services'
    }
  },
  data() {
    return {

    }
  },
  methods: {
    redirect(name) {
      this.$router.push({name});
    }
  }
}
</script>
