<template>
  <div id="home">
    <v-container fluid class="pa-0">
      <v-img max-height="858" class="align-center" :aspect-ratio="10 / 10" :src="get_image.img_0">
        <v-row class="text-center" justify="center">
          <v-col lg="8" md="8" sm="12" cols="12" class="home-title-content-simple">
              <h2 v-if="false" @click="editar('home.title')">{{ $t('home.title') }}</h2>
              <p @click="editar('home.titleSubText')">
                {{ $t('home.titleSubText') }}
              </p>
              {{ $t('home.singleTitle') }}
          </v-col>
        </v-row>
      </v-img>
    </v-container>
    <v-container class="mt-10">
      <v-row justify="center" align="center" class="mb-10">
        <v-col class="col-md-8">
          <h2
            @click="editar('home.wewanttobetitle')"
            class="text-center home-subtitle"
          >
            {{ $t('home.wewanttobetitle') }}
          </h2>
        </v-col>
      </v-row>
    </v-container>
    <v-container style="margin-bottom: 50px">
      <v-row justify="center">
        <v-col
        v-for="(card, index) in $t('home.wewanttobelist')"
            :key="index"
            lg="6"
            md="8"
            sm="12"
            cols="12"
            style="margin-bottom: 23px"
        >
        <card-home
              :numElement="index"
              :titulo="card.title"
              :img="card.img"
              :detalle="card.text"
              :textButton="card.button"
        />
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-row justify="center" align="center" class="mb-10">
        <v-col cols="10">
          <h2
            @click="editar('home.aboutus')"
            class="home-medium-subtitle"
          >
            {{ $t('home.aboutus') }}
          </h2>
        </v-col>
      </v-row>
    </v-container>
    <v-container style="margin-bottom: 100px;">
      <v-row>
        <v-col class="col-12 col-md-3">
          <div class="section">
            <img :src="get_image.img_5" height="55" width="55" alt="" />
          </div>
          <p @click="editar('home.reasonswhy.textOne')" class="section-txt">
            {{ $t('home.reasonswhy.textOne') }}
          </p>
        </v-col>
        <v-col class="col-12 col-md-3">
          <div class="section">
            <img :src="get_image.img_6" height="55" width="55" alt="" />
          </div>
          <p @click="editar('home.reasonswhy.textTwo')" class="section-txt">
            {{ $t('home.reasonswhy.textTwo') }}
          </p>
        </v-col>
        <v-col class="col-12 col-md-3">
          <div class="section">
            <img :src="get_image.img_7" height="55" width="55" alt="" />
          </div>
          <p @click="editar('home.reasonswhy.textThree')" class="section-txt">
            {{ $t('home.reasonswhy.textThree') }}
          </p>
        </v-col>
        <v-col class="col-12 col-md-3">
          <div class="section">
            <img :src="get_image.img_8" height="55" width="55" alt="" />
          </div>
          <p @click="editar('home.reasonswhy.textFour')" class="section-txt">
            {{ $t('home.reasonswhy.textFour') }}
          </p>
        </v-col>
      </v-row>
    </v-container>

    <div class="home-bg-coverge-responsive">
      <v-container>
        <v-row align="center" justify="center">
          <v-col class="col-12 col-md-10">
            <h3 @click="editar('home.coverage')" class="card-title-home">
              {{ $t('home.coverage') }}
            </h3>
            <p @click="editar('home.coverage_details')" class="mt-4">
              {{ $t('home.coverage_details') }}
            </p>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <v-container v-if="$vuetify.breakpoint.smAndDown" fluid class="pa-0">
      <v-row justify="center">
        <v-col cols="11">
          <v-img
            class="align-end"
            position="20%"
            :aspect-ratio="5 / 6"
            :src="get_image.img_9"
          >
            <v-row justify="center">
              <v-col cols="12" class="text-center">
                <v-btn @click="localePathEx('coverage')" dark color="#E30707">{{
                  $t('home.coverage_btn')
                }}</v-btn>
              </v-col>
            </v-row>
          </v-img>
        </v-col>
      </v-row>
    </v-container>
    <div v-if="!$vuetify.breakpoint.smAndDown" class="home-bg-coverge">
      <v-container>
        <v-row align="center" justify="center">
          <v-col class="col-12 col-md-10">
            <div>
              <h3 @click="editar('home.coverage')" class="card-title-home">
                {{ $t('home.coverage') }}
              </h3>
              <p @click="editar('home.coverage_details')" class="mt-4">
                {{ $t('home.coverage_details') }}
              </p>
              <v-btn
                color="#E30707"
                dark
                depressed
                @click="$router.push('coverage')"
                >{{ $t('home.coverage_btn') }}</v-btn
              >
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <v-container class="my-10">
      <v-row
        v-if="!$vuetify.breakpoint.smAndDown"
        justify="center"
        align="center"
      >
        <v-col class="col-12 col-md-10 mb-10">
          <v-row class="flex-column-reverse flex-md-row">
            <div class="col-md-6">
              <h4 @click="editar('home.contact')" class="home-subtitle-1">
                {{ $t('home.contact') }}
              </h4>
              <p @click="editar('home.contact_details')" class="mt-4">
                {{ $t('home.contact_details') }}
              </p>
              <div class="target-btn">
                <v-btn
                  color="#E30707"
                  dark
                  depressed
                  @click="goToContactSection('contact')"
                  >{{ $t('home.contact_btn') }}</v-btn
                >
              </div>
            </div>
            <div class="col-md-6">
              <v-img
                height="300"
                contain
                :aspect-ratio="9/9"
                :src="$t('home.contact_btn') === 'More Info' ? get_image.img_11 : get_image.img_10"
              />
            </div>
          </v-row>
        </v-col>
        <v-col class="col-12 col-md-10 mb-10">
          <v-row class="flex-column-reverse flex-md-row">
            <v-col class="col-md-6 fill-height">
              <h4 @click="editar('home.certified')" class="home-subtitle-1">
                {{ $t('home.certified') }}
              </h4>
              <p @click="editar('home.certified_details')" class="mt-4">
                {{ $t('home.certified_details') }}
              </p>
              <div
                class="align-center justify-space-between d-sm-flex text-center"
              >
                <p
                  @click="editar('home.certified_details_two')"
                  class="mb-2 mb-md-0"
                >
                  {{ $t('home.certified_details_two') }}
                </p>
                <v-btn
                  color="#E30707"
                  dark
                  depressed
                  @click="goToContactSection('join')"
                  >{{ $t('home.certified_btn') }}</v-btn
                >
              </div>
            </v-col>
            <div class="col-md-6">
              <v-img
                height="300"
                contain
                :aspect-ratio="9/9"
                :src="get_image.img_12"
              />
            </div>
          </v-row>
        </v-col>
        <v-col class="col-12 col-md-10 mb-10"> </v-col>
      </v-row>
      <v-row
        v-if="$vuetify.breakpoint.smAndDown"
        justify="center"
        no-gutters
        class="marco"
      >
        <v-col cols="12" class="text-center">
          <v-img
            :aspect-ratio="5 / 5"
            height="319"
            :src="$t('home.contact_btn') === 'More Info' ? get_image.img_11 : get_image.img_10"
          />
        </v-col>
        <v-col
          @click="editar('home.contact')"
          cols="12"
          class="text-center subTituloMobile my-4"
        >
          {{ $t('home.contact') }}
          <v-btn
            class="ml-2"
            small
            height="50"
            color="#E30707"
            @click="localePathEx('contact-us')"
          >
            <v-icon color="white">mdi-arrow-right</v-icon></v-btn
          >
        </v-col>
        <v-col
          @click="editar('home.contact_details')"
          cols="10"
          class="textoMobil my-3 mb-9"
          >{{ $t('home.contact_details') }}
        </v-col>
      </v-row>
      <v-row
        v-if="$vuetify.breakpoint.smAndDown"
        justify="center"
        no-gutters
        class="marco mt-12"
        style="background-color: #f2f4f6"
      >
        <v-col cols="12" class="text-center">
          <v-img
            class=""
            :aspect-ratio="5 / 5"
            height="319"
            :src="get_image.img_12"
          />
        </v-col>
        <v-col
          @click="editar('home.certified')"
          cols="12"
          class="text-center subTituloMobile my-4"
        >
          {{ $t('home.certified') }}
          <v-btn
            class="ml-2"
            small
            height="50"
            color="#E30707"
            @click="goToContactSection('join')"
          >
            <v-icon color="white">mdi-arrow-right</v-icon></v-btn
          >
        </v-col>
        <v-col
          @click="editar('home.certified_details')"
          cols="10"
          class="textoMobil my-3 mb-9"
          >{{ $t('home.certified_details') }}
        </v-col>
      </v-row>
    </v-container>

    <v-container class="mb-10" v-if="false">
      <v-row align="center" justify="center">
        <v-col class="col-12 col-md-10">
          <h3
            @click="editar('home.section_three')"
            class="home-subtitle-1 text-center"
          >
            {{ $t('home.section_three') }}
          </h3>
          <p @click="editar('home.st_details')" class="mt-4 text-center">
            {{ $t('home.st_details') }}
          </p>
        </v-col>
        <v-col class="col-12">
          <div class="home-certifications">
            <div v-for="(img, index) in get_image.img_13" :key="index" class="d-flex justify-center align-center">
              <img
                width="auto"
                height="48"
                :src="img"
                alt=""
              />
            </div>
          </div>
          <SwiperCertificates />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import SwiperCertificates from '../components/SwiperCertificates.vue';
import { HOME_PAGE } from '@/assets/img/images';
import cardHome from '@/components/home/CardHome.vue';

export default {
  name: 'IndexPage',
  nuxtI18n: {
    paths: {
      en: '/home',
      es: '/inicio',
    },
  },
  data() {
    return {
      
     }
  },
  metaInfo() {
    return {
      title: this.$t('home.seo.description'),
      meta: [
        { name: 'descripción', content: this.$t('home.seo.description') },
        { name: 'keywords', content: this.$t('home.seo.keywords') },
      ],
    };
  },

  components: {
    SwiperCertificates,
    cardHome
  },
  methods: {
    localePathEx(name) {
      this.$router.push({ name });
    },
    goToServiceSection(sectionName) {
      this.$router.push({ name: 'services', query: { section: sectionName } });
    },
    goToContactSection(sectionName) {
      this.$router.push({
        name: 'contact-us',
        query: { section: sectionName },
      });
    },
  },
  computed: {
    get_image() {
      return HOME_PAGE;
    },
  },
};
</script>
<style scoped>
video {  
  width: 100vw;
  height: 85vh;
  object-fit: cover;
}
.home-subtitle {
  font-size: 48px;
  color: #000;
}

.home-medium-subtitle {
  font-size: 32px;
  text-align: justify;
  text-justify: inter-word;
  color: #000;
}

.home-certifications {
  grid-template-columns: repeat(5, 1fr);
  display: grid;
  grid-column-gap: 18px;
  grid-row-gap: 1em;
}

.home-title-content {
  align-content: center;
  height: 120vh;
  padding-bottom: 2rem;

  text-align: center;
  letter-spacing: -0.02em;

  color: #ffffff;
  text-shadow: 3px 3px 4px rgba(0, 0, 0, 0.38);
}

.home-title-content-simple {
  font-style: normal;
  font-weight: bold;
  font-size: 50px;
  color: #ffffff;
}

.home-title-content h2 {
  font-style: normal;
  font-weight: bold;
  font-size: 72px;
  padding: 7rem 15% 0;
}

.home-title-content p {
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
}

.home-title-img {
  background-image: url('https://res.cloudinary.com/omni-international-logistics/image/upload/v1642133094/Img%20Omni%20WEB/Pag%20HOME/Header/service_rgib2n.png');
  height: 100%;
  width: 100%;
  background-size: 100% 100%;
  background-position: center;
}

.home-subtitle-1 {
  font-size: 36px;
  color: #000;
}

.home-flex-wrap {
  flex-wrap: nowrap;
}

.home-card-bg {
  background-color: #f2f4f6;
}

.card-title-home {
  line-height: normal;
  color: #000;
  font-size: 24px;
}

.home-bg-coverge {
  height: 436px;
  display: flex;
  align-items: center;
  background-color: #f2f4f6;
  background-position-x: right;
  background-size: auto 100%;
  background-image: url('https://res.cloudinary.com/omni-international-logistics/image/upload/v1642134508/Img%20Omni%20WEB/Pag%20HOME/Seccion%20Coverage/continent_dyobdi.png');
}

.home-bg-coverge-responsive {
  display: none;
}

.section {
  display: flex;
  margin-bottom: 3rem;
  justify-content: center;
}

.section-badge {
  background: #ccd4ff;
  height: 56px;
  width: 56px;

  border-radius: 50px;
}

.section-badge-img {
  height: 100%;
  width: 100%;

  background-image: url('../static/images/home/img.png');
  background-position: center;
}

.section-line {
  max-width: 70%;
  padding: 1rem;
  background-position: center;
}

.target-btn {
  text-align: right;
}

@media screen and (max-width: 1000px) {
  .home-title-content-simple {
    line-height: 35px;
    font-size: 32px;
  }
  .home-title-content h2 {
    padding: 7rem 15% 0;
    line-height: 35px;
    font-size: 45px;
  }
  .home-title-content p {
    padding: 0 15% 0;
    margin: 15px 0 0 0;
    font-size: 22px;
    line-height: 28px;
  }
}

@media screen and (max-width: 959px) {
  .home-certifications {
    display: none;
  }

  .home-medium-subtitle {
  font-size: 18px;
  }

  .home-subtitle {
    font-size: 36px;
  }

  .home-subtitle-1 {
    font-size: 24px;
  }

  .home-flex-wrap {
    flex-wrap: wrap;
  }

  .target-btn {
    text-align: center;
  }

  .section {
    display: none;
  }
  .section-txt {
    margin: 0;
    padding: 1rem;
    background: #f2f4f6;
    border-radius: 3px;
  }

  .home-bg-coverge {
    background-position: center;
  }

  .home-bg-coverge div {
    display: none;
  }

  .home-bg-coverge-responsive {
    display: initial;
    text-align: center;
  }

  .home-bg-coverge-responsive h3 {
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 35px;

    letter-spacing: -1px;
    color: #000;
  }

  .home-bg-coverge-responsive p {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 32px;

    text-align: center;
    font-feature-settings: 'salt' on, 'liga' off;

    color: #4d4d4d;
  }
}

@media screen and (max-width: 600px) {
  .home-title-content h2 {
    padding: 5rem 10% 0;
    font-size: 29px;
  }
  .home-title-content p {
    padding: 0 25% 0;
  }

  .home-subtitle {
    font-size: 24px;
  }

  .home-title-img {
    background-size: auto 100%;
  }
}
</style>
